import React, { useState, useCallback, useEffect } from 'react';
import ScanResults from '../../Components/Scanner';
import CameraScanner from '../../Components/QRscan';
import { useSDK } from '../../scandItSetUp';
import "./index.scss"

const Scan = () => {
  const [scannedData, setScannedData] = useState(null);
  const [invalidBarcode, setInvalidBarcode] = useState(false);

  const {sdk} = useSDK();

  const onBarcodeScanned = useCallback((scannedItem) => {
    let cleanedData = scannedItem.data;
    if (scannedItem.type === 'qr') {
    if (!cleanedData.includes('https://gcpbc.co/')) {
        setInvalidBarcode(true);
        return;
      }
    } 

    if (cleanedData.length === 13 && cleanedData.startsWith('0')) {
      cleanedData = cleanedData.slice(1);
    }
    setScannedData((prev) => {
      if (prev && prev.data === cleanedData && prev.type === scannedItem.type) {
        return prev;
      }
      return { data: cleanedData, type: scannedItem?.type };
    });
    setInvalidBarcode(false);
  }, []);

   useEffect(() => {
    let timer;
    if (invalidBarcode) {
      timer = setTimeout(() => setInvalidBarcode(false), 2500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [invalidBarcode]);


  useEffect(() => {
    return async () => {
        await sdk.enableCamera(false);
        await sdk.enableScanning(false);
    }
  }, [])

  return (
    <div className="scan-page">
      <CameraScanner onBarcodeScanned={onBarcodeScanned} />
      <ScanResults scannedBarcode={scannedData} invalidCode={invalidBarcode} />
    </div>
  );
};

export default Scan;
