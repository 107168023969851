import { getProductsDBVersionFromDB, saveProductDataToDB, saveProductsDBVersionToDB } from "../db/db"

/* generate radial color for GS Chart */
export const getRadialColor = (value) => {
  let color = ''
  if (value >= 0 && value <= 25) {
    color = '#f15754'
  } else if (value >= 26 && value <= 49) {
    color = '#ef9434'
  } else if (value >= 50 && value <= 74) {
    color = '#edcd25'
  } else if (value >= 75 && value <= 89) {
    color = '#97d50e'
  } else if (value >= 90 && value <= 100) {
    color = '#3ec60e'
  }
  return color
}

// capitalize first char of string
export const capitalizeFirstChar = (str) => {
  return (str && str.substr(0, 1).toUpperCase() + str.substr(1)) || ''
}

export const getFormattedHeading = (term) => {
  if (term) {
    return term.charAt(0).toUpperCase() + term.slice(1);
  }
  return ""
}

export const isSimpleString = (str) => {
  var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return !urlPattern.test(str);
}

export const getActivePropertiesInfo = (nutrition, foodSafety, processing, environment) => {
  const activeProperties = {
    nutrition: nutrition === "1",
    foodSafety: foodSafety === "1",
    processing: processing === "1",
    environment: environment === "1"
  };

  const activePropertiesArray = Object.keys(activeProperties).filter(prop => activeProperties[prop]);
  let activeClass = "";

  switch (activePropertiesArray.length) {
    case 1:
      activeClass = "one-pie";
      break;
    case 2:
      activeClass = "two-pie";
      break;
    case 3:
      activeClass = "three-pie";
      break;
    case 4:
      activeClass = "";
      break;
    default:
      activeClass = "";
      break;
  }

  return { activeClass, activePropertiesList: activePropertiesArray };
};

// export const downloadAndStoreJsonData = async () => {
//   try {
//     const response = await fetch('/api/products-stream');
//     if (!response.body) {
//       console.error("Streaming not supported in this browser.");
//       return;
//     }

//     const worker = new Worker('/worker.js');
//     worker.onmessage = (event) => {
//       if (event.data.status === "done") {
//         console.log("Worker processed a batch successfully.");
//       } else if (event.data.status === "error") {
//         console.error("Worker error:", event.data.error);
//       }
//     };

//     const reader = response.body.getReader();
//     const decoder = new TextDecoder();
//     let buffer = '';

//     while (true) {
//       const { done, value } = await reader.read();
//       if (done) break;
//       buffer += decoder.decode(value, { stream: true });

//       const lines = buffer.split('\n');
//       buffer = lines.pop();

//       for (const line of lines) {
//         if (line.trim()) {
//           try {
//             const batch = JSON.parse(line);
//             worker.postMessage({ products: batch });
//           } catch (err) {
//             console.error("Error parsing batch:", err, "Line:", line);
//           }
//         }
//       }
//     }

//     // Process any remaining data in the buffer
//     if (buffer.trim()) {
//       try {
//         const batch = JSON.parse(buffer);
//         worker.postMessage({ products: batch });
//       } catch (err) {
//         console.error("Error parsing final batch:", err, "Buffer:", buffer);
//       }
//     }

//     console.log("Finished streaming products.");
//   } catch (error) {
//     console.error("Error during JSON sync:", error);
//   }
// };


