import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { router } from './Routes';
import DisabledPage from './Pages/DisabledPage';
import { FiltersProvider } from './Context/FiltersContext';
import Loader from './Components/Loader';
import initDB, { deleteOldDB } from './db/db';
import { SearchProvider } from './Context/SearchContext';
import { AnimatePresence } from 'framer-motion';
import { SDKProvider } from './scandItSetUp';
import { getClientInfo } from './Utils/ClientInfo';
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [clientInfo, setClientInfo] = useState({
    name: '',
    fullName: '',
    location: '',
    status: 'enabled',
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    const initializeDatabase = async () => {
      try {
        await initDB();
        console.log('Database initialized');
      } catch (error) {
        console.error('Error initializing database:', error);
      }
    };

    const fetchClientInfo = async () => {
      try {
        const info = await getClientInfo();
        setClientInfo(info);
      } catch (error) {
        console.error('Error fetching client info:', error);
      }
    };

    initializeDatabase();
    fetchClientInfo();

    const isStandalone =
      window.matchMedia("(display-mode: standalone)").matches ||
      navigator.standalone;

    const updateViewportHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.visualViewport.height * 0.01}px`
      );
    };

    if (isStandalone) {
      updateViewportHeight();
      window.addEventListener("resize", updateViewportHeight);
    }

    return () => {
      window.removeEventListener("resize", updateViewportHeight);
      clearTimeout(timer);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      <ToastContainer position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        style={{ zIndex: 999999 }}
      />
      {isLoading ?
        <Loader /> :
        <>
          {clientInfo?.status === "disabled"
            ? <DisabledPage />
            : <FiltersProvider>
                <SearchProvider>
                 <SDKProvider>
                    <RouterProvider
                      router={router}
                      fallbackElement={<Spin fullscreen={false} size="small" />}
                    />
                 </SDKProvider>
                </SearchProvider>
              </FiltersProvider>
            }
        </>}
    </AnimatePresence>
  );
}

export default App;
